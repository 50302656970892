<template>
  <div class="overview">
    <div class="main">
      <div class="head_bg">
        <img src="../../assets/images/area_zh.png" alt="">
      </div>
      <!-- <div class="tableBox">
        <div class="second-title">
          <div class="shu" />项目统计信息
        </div>
        <Table stripe :columns="areaColumns" :data="areaData"></Table>
      </div> -->
      <Row style="height: 100%">
        <i-col span="6" style="height: 100%">
          <div class="left-content">
            <div class="card-box" style="">
              <div class="head">
                <div class="title">
                  {{ currentBox == "all" ? "珠海市" : currentArea }}项目信息总览
                </div>
                <div class="small-label">
                  <!-- <Tag color="purple">月</Tag> -->
                  <!-- <Tag color="green">周</Tag> -->
                  <!-- <Tag color="blue">日</Tag> -->
                </div>
              </div>
              <div class="content">
                <div class="cb-info">
                  <div class="cbi-item">
                    <div class="label">总项目数：</div>
                    <div class="info">
                      {{ viewData.totalProjectCount || 0 }}个
                    </div>
                  </div>
                  <div class="cbi-item" v-if="false">
                    <div class="label">已上平台项目数：</div>
                    <div class="info">
                      {{ viewData.totalProjectWithinDGCount || 0 }}个
                    </div>
                  </div>
                  <div class="cbi-item">
                    <div class="label">总企业数：</div>
                    <div class="info">{{ viewData.totalCorpCount || 0 }}个</div>
                  </div>
                  <div class="cbi-item">
                    <div class="label">总人数：</div>
                    <div class="info">
                      {{ viewData.totalWorkerCount || 0 }}人
                    </div>
                  </div>
                  <div class="cbi-item">
                    <div class="label">已实名总人数：</div>
                    <div class="info">
                      {{ viewData.totalRealNameCount || 0 }}人
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-box" v-if="currentBox == 'all'">
              <div class="head">
                <div class="title">实名覆盖率（%）</div>
                <div class="small-label">
                  <!-- <Tag color="purple">月</Tag> -->
                  <!-- <Tag color="green">周</Tag> -->
                  <!-- <Tag color="blue">日</Tag> -->
                </div>
              </div>
              <div class="content">
                <div id="rnBar" class="rnbar-style"></div>
              </div>
            </div>
            <div class="card-box" v-if="currentBox == 'all'">
              <div class="head">
                <div class="title">绿码覆盖率（%）</div>
                <div class="small-label"></div>
              </div>
              <div class="content">
                <div id="pBar" class="rnbar-style"></div>
              </div>
            </div>
            <div class="card-box" v-if="currentBox == 'area'">
              <div class="head">
                <div class="title">实名覆盖率（%）</div>
                <div class="small-label"></div>
              </div>
              <div v-if="!itcNull" class="content">
                <div id="chartPieRN" class="pie-wrap"></div>
              </div>
              <div v-if="itcNull" class="nullcontent">暂无数据</div>
            </div>
            <div class="card-box" v-if="currentBox == 'area'">
              <div class="head">
                <div class="title">绿码覆盖率（%）</div>
                <div class="small-label"></div>
              </div>
              <div v-if="!itcNull" class="content">
                <div id="chartPiePO" class="pie-wrap"></div>
              </div>
              <div v-if="itcNull" class="nullcontent">暂无数据</div>
            </div>
          </div>
        </i-col>
        <i-col
          span="18"
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <div class="mapbox">
            <div id="mapChart" class="map-chart-style"></div>
          </div>

          <div class="table">
            <Card
              style="
                background: rgba(0, 71, 168, 0.85);
                border-color: rgba(0, 71, 168, 0.85);
                height: 40vh;
                overflow-y: auto;
              "
            >
              <div class="second-title">
                <div class="shu" />
                项目统计信息
              </div>
              <div style="display: flex;width: 100%;align-items: center;justify-content: flex-end;margin-bottom: 8px;" v-if="currentBox == 'area'">
                <Input style="width: 250px;" search enter-button="搜索" placeholder="请输入项目名" v-model="searchProject" @on-search="handleSearchProject"/>
                <Page  
                  class="page-box"
                  @on-change="handleDetailPageChange"
                  :page-size="10"
                  :total="detailTotal"
                />
              </div>
              
              <!-- <Table stripe :columns="areaColumns" :data="areaData"></Table> -->
              <div class="tableInfobox" v-if="currentBox == 'all'">
                <div class="tableinfo-style table-head">
                  <div class="">区域</div>
                  <div class="">项目总数</div>
                  <div class="">已上平台项目数量</div>
                  <div class="">总人数</div>
                  <div class="">企业数量</div>
                </div>
                <!-- <div v-if="!vNull"> -->
                <div
                  class="tableinfo-style table-content"
                  v-for="(item, index) in viewList"
                  :key="index"
                  @click="handlePickAreaTable(item)"
                >
                  <div class="">{{ item.areaName }}</div>
                  <div class="">{{ item.totalProjectCount }}</div>
                  <div class="">{{ item.totalProjectWithinDGCount }}</div>
                  <div class="">{{ item.totalWorkerCount }}</div>
                  <div class="">{{ item.totalCorpCount }}</div>
                </div>
                <!-- </div> -->
                <!-- <div v-else> -->
                <div
                  v-if="viewList.length < 1"
                  class="tableinfo-style table-content"
                  style="justify-content: center"
                >
                  暂无数据
                </div>
                <!-- </div> -->
              </div>
              <div class="tableInfobox" v-if="currentBox == 'area'">
                <div class="tableinfo-style table-head">
                  <div class="">项目名称</div>
                  <div class="">建设单位</div>
                  <div class="">负责人姓名</div>
                  <div class="">负责人手机号码</div>
                  <div class="">项目总人数</div>
                  <div class="">已实名人数</div>
                  <div class="">实名覆盖率（%）</div>
                  <div class="">绿码覆盖率（%）</div>
                </div>
                <!-- <div v-if="!vNull"> -->
                <div
                  class="tableinfo-style table-content"
                  v-for="(item, index) in viewList"
                  :key="index"
                >
                  <div class="">{{ item.projectName }}</div>
                  <div class="">{{ item.contractorCorpName }}</div>
                  <div class="">{{ item.responsiblePersonName }}</div>
                  <div class="">{{ item.responsiblePersonPhone }}</div>
                  <div class="">{{ item.totalWorkerCount || 0 }}</div>
                  <div class="">{{ item.totalRealNameCount || 0 }}</div>
                  <div class="">{{ (item.realNameCoveragePer * 100) + '%' || 0 }}</div>
                  <div class="">{{ (item.interTaskCoveragePer * 100) + '%' || 0 }}</div>
                </div>
                <!-- </div> -->
                <!-- <div v-else> -->
                <div
                  v-if="vNull"
                  class="tableinfo-style table-content"
                  style="justify-content: center"
                >
                  暂无数据
                </div>
                <!-- </div> -->
              </div>
            </Card>
          </div>
        </i-col>
        <!-- <i-col span="6">i-col-12</i-col> -->
      </Row>
    </div>
    <div class="statistical"></div>
    <div class="backBtn" v-if="currentBox == 'area'" @click="backAll">
      返回珠海市总览
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import geoCoordMap from "./zhuhai.json";
export default {
  name: "overView",
  data() {
    return {
      mapChart: null,
      rnBar: null,
      pBar: null,
      chartPieRN: null,
      chartPiePO: null,
      realNameCoverage: [],
      viewData: {},
      currentArea: "",
      areaList: [
        { name: "斗门区", value: "1", id: "440403" },
        { name: "高栏港区", value: "0", id: "440493" },
        { name: "金湾区", value: "0", id: "440404" },
        { name: "高新区", value: "0", id: "440492" },
        { name: "香洲区", value: "0", id: "440402" },
        { name: "保税区", value: "0", id: "440491" },
        { name: "横琴新区", value: "0", id: "440495" },
        { name: "万山区", value: "0", id: "440494" },
      ],
      areaColumns: [
        {
          title: "区域",
          key: "areaName",
        },
        {
          title: "项目总数",
          key: "areaProjectTotal",
        },
        {
          title: "已上平台项目数量",
          key: "onPlatFormTotal",
        },
        {
          title: "总人数",
          key: "total",
        },
        {
          title: "企业数量",
          key: "enterpriseTotal",
        },
      ],
      areaData: [
        {
          areaName: "斗门区",
          areaProjectTotal: "100",
          onPlatFormTotal: "1",
          total: "10",
          enterpriseTotal: "10",
        },
        {
          areaName: "高栏港区",
          areaProjectTotal: "100",
          onPlatFormTotal: "0",
          total: "10",
          enterpriseTotal: "10",
        },
        {
          areaName: "金湾区",
          areaProjectTotal: "100",
          onPlatFormTotal: "0",
          total: "10",
          enterpriseTotal: "10",
        },
        {
          areaName: "高新区",
          areaProjectTotal: "100",
          onPlatFormTotal: "0",
          total: "10",
          enterpriseTotal: "10",
        },
        {
          areaName: "香洲区",
          areaProjectTotal: "100",
          onPlatFormTotal: "0",
          total: "10",
          enterpriseTotal: "10",
        },
        {
          areaName: "保税区",
          areaProjectTotal: "100",
          onPlatFormTotal: "0",
          total: "10",
          enterpriseTotal: "10",
        },
        {
          areaName: "横琴新区",
          areaProjectTotal: "100",
          onPlatFormTotal: "0",
          total: "10",
          enterpriseTotal: "10",
        },
        {
          areaName: "万山区",
          areaProjectTotal: "100",
          onPlatFormTotal: "0",
          total: "10",
          enterpriseTotal: "10",
        },
      ],
      interTaskCoverage: [],
      currentBox: "all", //all是全部 area是单区域
      viewList: [],
      currentViewList: [],
      currentUserInfo: {},
      rncNull: false,
      itcNull: false,
      vNull: false,
      detailPage: 1,
      detailTotal: 0,
      searchProject: ''
    };
  },
  mounted() {
    // let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
    // if(userInfo){
    // }else{
    //   this.$router.push({ name: "login" });
    // }
    let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
    this.currentUserInfo = userInfo;
    if (this.currentUserInfo) {
      this.getViewData();
      this.getViewDetail();
      this.drawMapChart();
    } else {
      this.$router.push({ name: "login" });
    }
    // this.drawProjectBarChart();
    // this.drawRealNamePieChart();
    // this.drawProjectPieChart();
  },
  watch: {
    openName() {
      this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      });
    },
  },
  methods: {
    getViewData() {
      let data = {
        phone: this.currentUserInfo.login_id,
        cityId: "440400",
      };
      this.$fetch(`/sapi/stat/getZhCityStat`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // this.$Message.success("操作成功")
          // this.rosterListPage = 1
          // this.rosterListLoading = true
          // this.getRosterList()
          this.viewData = res.data;
          this.realNameCoverage = res.data.realNameCoverage;
          this.interTaskCoverage = res.data.interTaskCoverage;
          console.log(res);
          this.drawRealNameBarChart();
          this.drawProjectBarChart();
        } else {
          this.$Message.error("操作失败，请稍后再试");
        }
        // console.log(res)
      });
    },
    getViewDetail() {
      let data = {
        phone: this.currentUserInfo.login_id,
        cityId: "440400",
        // areaId: '440492',
      };
      this.$fetch(`/sapi/stat/getZhStatDetail`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // console.log(res);
          this.currentViewList = JSON.parse(JSON.stringify(res.resList));
          this.viewList = res.resList;
        } else {
          this.$Message.error("操作失败，请稍后再试");
        }
        // console.log(res)
      });
    },
    getMapOption() {
      let option = {
        backgroundColor: "#0868c9",
        title: {
          text: "",
          x: "center",
          itemGap: 2,
          subtextStyle: {
            color: "#fff",
          },
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return params.name + " : " + params.value[2];
          },
        },
        // legend: {
        //   orient: "vertical",
        //   y: "bottom",
        //   x: "right",
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
        geo: {
          map: "zhuhai",
          roam: false,
          zoom: 1.0,
          layoutSize: "100%",
          top: "10",
          bottom: "10",
          label: {
            show: true,
            color: "#fff",
            emphasis: {
              show: true,
            },
          },
          scaleLimit: {
            min: 1,
            max: 1,
          },
          regions: [
            {
              name: this.currentArea,
              itemStyle: {
                areaColor: "#02c7d3",
                color: "#fff",
              },
              label: {
                show: true,
                color: "#fff",
              },
            },
          ],
          itemStyle: {
            normal: {
              areaColor: "#060d2a",
              color: "#fff",
              borderColor: "#5cadff",
            },
            emphasis: {
              borderColor: "#fff",
              borderWidth: 1,
              color: "#fff",
              areaColor: "#5cadff",
            },
          },
        },
        // toolbox: {
        //   show: true,
        //   right: "left",
        //   top: 0,
        //   feature: {
        //     dataView: { readOnly: false },
        //     restore: {},
        //     saveAsImage: {},
        //   },
        // },
        series: [
          {
            name: "珠海市地区分布图",
            type: "scatter",
            coordinateSystem: "geo",
            label: {
              normal: {
                color: "#fff",
                formatter: "{b}",
                position: "bottom",
                show: true,
              },
            },
            // data: data,
            symbolSize: 30,
            encode: { value: 2 },
            symbol: "pin",
            zlevel: 1,
          },
        ],
      };
      return option;
    },
    drawMapChart() {
      let that = this;
      let arealist = this.areaList;
      this.mapChart = echarts.init(document.getElementById("mapChart"));
      echarts.registerMap("zhuhai", geoCoordMap);

      this.mapChart.setOption(this.getMapOption());
      this.mapChart.on("click", function (params) {
        that.currentArea = params.name;
        for (let i = 0; i < arealist.length; i++) {
          arealist[i].value = "0";
          if (params.name == arealist[i].name) {
            arealist[i].value = "1";
          }
        }
        that.mapChart.setOption(that.getMapOption());
        that.handlePickArea(params);
      });
    },
    // 处理选择区域
    handlePickArea(data) {
      console.log(data,this.viewList);
      let tlist = this.currentViewList
      let tflag = 1
      tlist.forEach(val=>{
        console.log(val.areaName,data.name)
        if(val.areaName == data.name){
          tflag = 2
        }
      })
      if(tflag == 1){
        this.$Message.warning('您当前没有该区域数据查看权限')
      }
      if (this.currentBox == "all") {
        this.rnBar.dispose();
        this.pBar.dispose();
        this.currentBox = "area";
        this.getAreaData();
        this.getAreaDetail();
        setTimeout(() => {
          this.drawRealNamePieChart();
          this.drawProjectPieChart();
        }, 500);
        return;
      }
      if (this.currentBox == "area") {
        this.detailPage = 1
        this.searchProject = ""
        this.getAreaData();
        this.getAreaDetail();
        this.chartPieRN.dispose();
        this.chartPiePO.dispose();
      }
    },
    backAll() {
      this.currentArea = "";
      this.mapChart.setOption(this.getMapOption());
      this.currentBox = "all";
      this.chartPieRN.dispose();
      this.chartPiePO.dispose();
      this.getViewData();
      this.getViewDetail();
      setTimeout(() => {
        this.drawRealNameBarChart();
        this.drawProjectBarChart();
      }, 500);
    },
    getAreaData() {
      let alist = this.areaList;
      let areaId = "";
      alist.forEach((val) => {
        if (val.name == this.currentArea) {
          areaId = val.id;
        }
      });
      let data = {
        phone: this.currentUserInfo.login_id,
        cityId: "440400",
        areaId: areaId,
      };
      this.$fetch(`/sapi/stat/getZhCityStat`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // this.$Message.success("操作成功")
          // this.rosterListPage = 1
          // this.rosterListLoading = true
          // this.getRosterList()
          this.viewData = res.data;
          this.realNameCoverage = res.data.realNameCoverage;
          this.interTaskCoverage = res.data.interTaskCoverage;
          if (this.realNameCoverage == null || this.realNameCoverage.length < 1) {
            this.rncNull = true;
          } else {
            this.rncNull = false;
          }
          if (this.interTaskCoverage == null || this.interTaskCoverage.length < 1) {
            this.itcNull = true;
          } else {
            this.itcNull = false;
          }
          console.log(res);
          setTimeout(() => {
            this.drawRealNamePieChart();
            this.drawProjectPieChart();
          }, 500);
          // this.drawRealNameBarChart();
          // this.drawProjectBarChart();
        } else {
          this.$Message.error("操作失败，请稍后再试");
        }
        // console.log(res)
      });
    },
    handleSearchProject(){
      // console.log(this.searchProject)
      this.getAreaDetail()
    },
    handlePickAreaTable(item){
      this.currentArea = item.areaName
      this.mapChart.setOption(this.getMapOption());
      this.handlePickArea();
    },
    getAreaDetail() {
      let alist = this.areaList;
      let areaId = "";
      alist.forEach((val) => {
        if (val.name == this.currentArea) {
          areaId = val.id;
        }
      });
      let data = {
        phone: this.currentUserInfo.login_id,
        cityId: "440400",
        areaId: areaId,
        pageNumber: this.detailPage,
        projectName: this.searchProject
      };
      this.$fetch(`/sapi/stat/getZhStatDetail`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          console.log(res);
          this.viewList = res.resList;
          this.detailTotal = res.total
          if ( this.viewList == null || this.viewList.length < 1 ) {
            this.vNull = true;
          } else {
            this.vNull = false;
          }
        } else {
          this.$Message.error("操作失败，请稍后再试");
        }
        // console.log(res)
      });
    },
    handleDetailPageChange(e){
      this.detailPage = e
      this.getAreaDetail()
      // console.log(e)
    },
    drawRealNameBarChart() {
      let list = this.realNameCoverage;
      let labelList = [];
      let rDataList = [];
      list.forEach((val, index) => {
        labelList.push(val.areaName);
        rDataList.push(val.realNameCoveragePer * 100);
      });
      console.log(labelList, rDataList);
      this.rnBar = echarts.init(document.getElementById("rnBar"));
      let option = {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          show: true,
          formatter: function (params) {
            return params[0].data + '%';
          }
        },
        grid: {
          top: "3%",
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: labelList,
            axisTick: {
              alignWithLabel: true,
            },
            nameGap: "5",
            nameTextStyle: {
              fontSize: "10",
            },
            axisLabel: {
              show: true,
              interval: 0,
              rotate: 40,
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            max: "100",
            min: "0",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        series: [
          {
            name: "覆盖率",
            type: "bar",
            barWidth: "15",
            data: rDataList,
          },
        ],
      };
      this.rnBar.setOption(option);
    },
    drawProjectBarChart() {
      let list = this.interTaskCoverage;
      let labelList = [];
      let rDataList = [];
      list.forEach((val, index) => {
        labelList.push(val.areaName);
        rDataList.push(val.interTaskCoveragePer * 100);
      });
      this.pBar = echarts.init(document.getElementById("pBar"));
      let option = {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          show: true,
          formatter: function (params) {
            return params[0].data + '%';
          }
        },
        grid: {
          top: "3%",
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: labelList,
            axisTick: {
              alignWithLabel: true,
            },
            nameGap: "5",
            nameTextStyle: {
              fontSize: "10",
            },
            axisLabel: {
              show: true,
              interval: 0,
              rotate: 40,
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            max: "100",
            min: "0",

            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        series: [
          {
            name: "覆盖率",
            type: "bar",
            barWidth: "15",
            data: rDataList,
          },
        ],
      };
      this.pBar.setOption(option);
    },
    drawRealNamePieChart() {
      let option = {
        title: {
          text: `${this.realNameCoverage[0].areaName}`,
          left: "center",
          top: "47%",
          // padding:[24,0],
          textStyle: {
            color: "#fff",
            fontSize: 12,
            align: "center",
          },
        },
        tooltip: {
          //提示框组件。
          trigger: "item", //'item' 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          formatter: "{b} : {d}%", //{a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
        },
        //         legend: {
        //     orient: 'vertical',
        //     left: 10,
        //     data: ['20-25岁', '26-30岁', '31-35岁', '36-40岁', '41岁以上']
        // },
        color: ["#2db7f5", "#ff9900"],
        grid: {
          top: "0%",
          left: "0%",
          right: "0%",
          bottom: "0%",
        },
        series: [
          {
            name: "实名占比",
            type: "pie",
            radius: ["45%", "68%"], //饼图的半径
            center: ["50%", "50%"], //饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。
            data: [
              {
                value: this.realNameCoverage[0].nonRealNameCoveragePer * 100,
                name: `未实名占比`,
              },
              {
                value: this.realNameCoverage[0].realNameCoveragePer * 100,
                name: `已实名占比`,
              },
            ],
            itemStyle: {
              //图形样式。
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              length: "5",
              length2: "10",
            },
          },
        ],
      };
      this.chartPieRN = echarts.init(document.getElementById("chartPieRN"));
      this.chartPieRN.setOption(option);
    },
    drawProjectPieChart() {
      let option = {
        title: {
          text: `${this.interTaskCoverage[0].areaName}`,
          left: "center",
          top: "47%",
          // padding:[24,0],
          textStyle: {
            color: "#fff",
            fontSize: 12,
            align: "center",
          },
        },
        tooltip: {
          //提示框组件。
          trigger: "item", //'item' 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          formatter: "{b} : {d}%", //{a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
        },
        //         legend: {
        //     orient: 'vertical',
        //     left: 10,
        //     data: ['20-25岁', '26-30岁', '31-35岁', '36-40岁', '41岁以上']
        // },
        color: ["#2db7f5", "#ff9900"],
        grid: {
          top: "0%",
          left: "0%",
          right: "0%",
          bottom: "0%",
        },
        series: [
          {
            name: "实名占比",
            type: "pie",
            radius: ["45%", "68%"], //饼图的半径
            center: ["50%", "50%"], //饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。
            data: [
              {
                value: this.interTaskCoverage[0].nonInterTaskCoveragePer * 100,
                name: `未实名占比`,
              },
              {
                value: this.interTaskCoverage[0].interTaskCoveragePer * 100,
                name: `已实名占比`,
              },
            ],
            itemStyle: {
              //图形样式。
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              length: "5",
              length2: "10",
            },
          },
        ],
      };
      this.chartPiePO = echarts.init(document.getElementById("chartPiePO"));
      this.chartPiePO.setOption(option);
    },
  },
};
</script>
<style scoped>
.page-box >>> .ivu-page-next,.page-box >>> .ivu-page-prev, .page-box >>> .ivu-page-item{
  background: rgba(0, 71, 168, 0.85);
  color: #fff;
}
.page-box >>> .ivu-page-next a,.page-box >>> .ivu-page-prev a, .page-box >>> .ivu-page-item a{
  color: #fff;
}
</style>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
body {
}
.page-box{
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: 8px;
  // padding: 4px 4px 8px 4px;
}
.map-chart-style {
  width: 100%;
  // min-height: 600px;
  height: 50vh;
  // height: 100vh;
  // max-height: 800px;
}
.rnbar-style {
  width: 100%;
  height: 250px;
}
.pie-wrap {
  width: 100%;
  height: 250px;
}
.nullcontent {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-title {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  color: #fff;
  .shu {
    width: 5px;
    height: 14px;
    background: #00fff6;
    margin-right: 6px;
  }
}
.head_bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  display: flex;
  justify-content: center;
  img{
    width: 50%;
    height: auto;
  }
}
.overview {
  background: #0868c9;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  .backBtn {
    position: absolute;
    right: 10%;
    top: 10%;
    cursor: pointer;
    background: rgba(0, 71, 168, 0.85);
    color: #fff;
    border-radius: 6px;
    padding: 14px 24px;
    font-size: 14px;
  }
  .main {
    min-width: 1200px;
    position: relative;
    height: 100%;
    .tableBox {
      position: absolute;
      right: 10%;
      top: 10%;
      width: 500px;
      z-index: 15;
      padding: 8px 14px;
      border-radius: 4px;
    }
    .table {
      width: 100%;
      padding: 8px 14px;
      // background: rgba(0,71,168,0.85);
      .second-title {
        color: #00fff6;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        font-size: 18px;
        .shu {
          width: 5px;
          height: 14px;
          background: #00fff6;
          margin-right: 6px;
        }
      }
      .tableInfobox {
        color: #fff;
        width: 100%;
        font-size: 16px;
        .tableinfo-style:nth-child(n + 2):hover {
          cursor: pointer;
          background: rgba(76, 134, 228, 0.6) !important;
          transition: all 0.3s linear;
        }
        .tableinfo-style {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
          border-radius: 6px;
          div {
            width: 20%;
            padding: 0 14px;
          }
        }

        .tableinfo-style:nth-child(odd) {
          background: rgba(0, 42, 111, 0.6);
          border-radius: 4px;
        }
      }
    }
    .left-content {
      padding: 8px 14px;
      // height: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .card-box {
        // background: #fff;
        background: rgba(0, 71, 168, 0.85);
        width: 100%;
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 14px 16px;
        max-width: 460px;
        display: flex;
        flex-direction: column;
        color: #fff;
        .head {
          padding: 14px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e8eaec;
          font-size: 18px;
          color: #00fff6;
        }
        .content {
          padding: 12px;
          font-size: 16px;
          .cb-info {
            .cbi-item {
              display: flex;
              margin-bottom: 4px;
              .label {
                margin-right: 8px;
              }
              .info {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
// 鼠标移入效果
@keyframes myCardHover {
  0% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15),
      0 6px 20px 0 rgba(0, 0, 0, 0.14);
  }
}
.tableBox:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
.card-box:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
</style>